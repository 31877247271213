
<template>
  <div class="form-item-upload boxsize clearboth inlineblock">
    <el-upload
        ref="upload"
        class="picture-uploader upload"
        v-bind="$attrs"
        name="imagePicker"
        :headers="headers"
        :action="api.url+ `/chat/webimplugin/visitor/tenants/${tenantId}/visitors/${accid}/mediafiles`"
        :show-file-list="showFileList"
        :auto-upload="true"
        :limit="limit"
        :on-success="onUploadSuccess"
        :before-upload="onUploadChange"
        :on-error="onErrorEvent">
          <slot />
      </el-upload>
  </div>
</template>

<script>
import api from "@/fetch/url";
import {getStore} from "@/utils/utils"
import { Base64 } from 'js-base64'
export default {
  name:"formItemUpLoad",
  data(){
    return {
      modelData:this.value,
      api,
      headers: {
        Authorization: 'Bearer ' + Base64.encode(`${getStore('visitorAccid')}:${getStore('token')}`),
        // 'Content-Type':"multipart/form-data",
        // Enctype:"multipart/form-data"
      },
      isUpload: true,
      initLoading: ''
    }
  },
  mounted(){
    // this.$emit("get-ref", this.$refs.upload);
  },
  methods:{
    onUploadSuccess(e) {
      if (this.isUpload) {
        if (e.code == 200) {
          console.log("============",e)
          this.modelData = e.entity.url;
          this.$emit("on-upload",e.entity.url);
          e.message&&this.$message.success({ message: e.message });
        }else{
          this.$emit('on-fail')
          // this.$message.warning({ message: e.errmsg })
        }
      }
    },
    onUploadChange(file) {
      const isIMAGE = file.type === "image/jpeg" || file.type === "image/png";
      const isLt1M = file.size / 1024 /1024 < this.fileSize;

      if (this.fileType === "image") {
        if (!isIMAGE) {
          this.$message.warning("请上传jpg/png图片!");
          this.isUpload = false;
          return false;
        }
      }
      if (!isLt1M) {
        this.$message.warning(`上传文件大小不能超过 ${this.fileSize}MB!`);
        this.isUpload = false;
        return false;
      } else {
        this.isUpload = true;
        this.$emit("on-change",file)
      }
    },
    onErrorEvent(e) {
      console.log("==上传失败==",e);
    },
  },
  props:{
    tenantId:String,
    accid:String,
    id:String,
    value:String,
    label:String,
    uploadTips:String,
    isImg: {
      default() {
        return true;
      }
    },
    showFileList: {
      default() {
        return false;
      }
    },
    limit: {
      default() {
        return 10;
      }
    },
    uploadType: {
      default() {
        return "default";
      }
    },
    fileSize: {
      default() {
        return 5;
      }
    },
    fileType: {
      default() {
        return "image";
      }
    }
  },
  watch:{
    value(e){
      this.modelData = e
    },
    modelData(e){
      this.$emit('upload',e)
    }
  },
}
</script>

<style lang="scss" scoped>
.form-item-upload{


}
</style>



